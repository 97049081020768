import React from 'react';
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import { UserContext } from '../hooks/UserProvider';

import * as routes from '../constants/routes';
import * as ga from '../utils/ga';

function AuthButton(props) {

  let history = useHistory();

  function handleSigninClick() {
    
    var path = routes.SIGNIN;

    ga.doGAEvent('header','sign-in-click');

    history.push(path);

  }

  if (props.user) {

    if (props.user.isAnonymous) {
      return (<Button
                color="inherit"
                onClick={handleSigninClick}
                >
                Sign In
              </Button>)
    } else {
      return (<Button
                color="inherit"
                onClick={props.logout}
                >
                Sign Out
              </Button>)
    }

  } else {
    return null
  }
}

export default function Header() {

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Grid
            container
            justify="space-between"
            >
            <Grid item>
              <Link variant="h6" color="inherit" noWrap href="/">
                S W E R V I E
              </Link>
            </Grid>
            <Grid item>
              <UserContext.Consumer>
                {({user,logout}) => <AuthButton user={user} logout={logout}/>}
              </UserContext.Consumer>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}