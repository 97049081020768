import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowUpRounded from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import IconButton from '@material-ui/core/IconButton';
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import * as ga from '../utils/ga';

import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    noFlex: {
      flexGrow: 0,
    },
    containerSpacer: {
      padding: theme.spacing(1),
    },
    categoryItem: {
      width: '100% !important',
    },
    paper: {
      padding: theme.spacing(1),
      width: '100%',
    },
    productSection: {
      margin: '5px 0px',
    },
    scoreBlock: {
      textAlign: 'center',
    },
    retailerBlock: {
      textAlign: 'center',
      margin: '5px 0px',
    },
    retailerWrapper: {
      marginTop: '0px'
    },
    productImage: {
      maxHeight: '140px',
      maxWidth: '100%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    checkMark: {
      padding: theme.spacing(0,1),
    },
  });


class CategoryProduct extends Component {
  constructor(props) {
    super(props)

    this.state = {
      upvote: 'primary',
      downvote: 'primary'
    };

    this.handleUpvoteClick = this.handleUpvoteClick.bind(this);
    this.handleDownvoteClick = this.handleDownvoteClick.bind(this);
    this.handleRetailerClick = this.handleRetailerClick.bind(this);

  }

  handleUpvoteClick(product) {
    ga.doGAEvent('category-list','product-upvote');
    if (this.state.upvote === 'secondary') {
      this.setState({
        upvote: 'primary'
      });
    } else {
      this.setState({
        upvote: 'secondary',
        downvote: 'primary'
      });
    }
  }
  
  handleDownvoteClick(product) {
    ga.doGAEvent('category-list','product-downvote');
    if (this.state.downvote === 'secondary') {
      this.setState({
        downvote: 'primary'
      });
    } else {
      this.setState({
        upvote: 'primary',
        downvote: 'secondary'
      });
    }
  }

  handleRetailerClick(url) {
    ga.doGAEvent('category-list','product-retailer-click');
    window.open(url);
  }

  render() {

    const { classes } = this.props

    if (this.props.product.data.urls) {

      var retailers = this.props.product.data.urls.map((retailer, index) => {

        return (
          <Grid item xs={12} className={classes.retailerWrapper}>
            <Button
              color="secondary"
              onClick={() => {this.handleRetailerClick(retailer.url)}}
              >
              {retailer.retailer}
            </Button>
          </Grid>
        )
      });

    }

    if (this.props.product.data.features) {

      var category_features = this.props.category.data.features.sort((a, b) => (a.index > b.index) ? 1 : -1)

      var features = category_features.map((feature, index) => {

        if (index < 8) {

          if (feature.type === 'scale') {

            if (this.props.product.data.features[feature.name]) {

              var star_count = [...Array(Math.round(this.props.product.data.features[feature.name]/2.0)).keys()]

              var stars = star_count.map(() => {
                return (
                  <StarRateRoundedIcon fontSize='small'></StarRateRoundedIcon>
                )
              })

              return (
                <Grid item xs={6}>
                  <Typography variant={'body2'}>{feature.name}</Typography>
                  {stars
                    ? stars
                    : null}
                </Grid>
              )

            }

          } else if (feature.type === 'boolean') {

            if (this.props.product.data.features[feature.name]) {

              return (
                <Grid container item xs={12} md={6}>
                  <Grid item>
                    <Typography variant={'body2'}>
                      {feature.name}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.checkMark}>
                    <Typography variant={'body2'}>
                      <CheckRoundedIcon fontSize='small'></CheckRoundedIcon>
                    </Typography>
                  </Grid>
                </Grid>
              )

            }

          }

        }

      })

    }
    
    return (
        <Grid container xs={12} className={classes.containerSpacer}>
          <Paper className={classes.paper}>
            <Grid container xs={12}>
              <Grid container item alignItems={"center"} xs={3} className={classes.containerSpacer}>
                <Grid item xs={12} md={4} className={classes.scoreBlock}>
                  <IconButton size={"small"} color={this.state.upvote} aria-label="up vote" component="span" onClick={this.handleUpvoteClick}>
                    <KeyboardArrowUpRounded />
                  </IconButton>
                  <Typography variant={'h4'}>{Math.round(this.props.product.data.score_overall)}</Typography>
                  <IconButton size={"small"} color={this.state.downvote} aria-label="up vote" component="span" onClick={this.handleDownvoteClick}>
                    <KeyboardArrowDownRounded />
                  </IconButton>
                </Grid>
                <Grid item xs={12} md={8}>
                  <img className={classes.productImage} alt={this.props.product.data.name} src={this.props.product.data.image_url}></img>
                </Grid>
              </Grid>
              <Grid container item justify={"flex-start"} xs={9} md={6} className={classes.containerSpacer}>
                <Grid item xs={12}>
                  <Typography><strong>{this.props.product.data.name}</strong></Typography>
                  <Typography variant={'subtitle2'}>by {this.props.product.data.brand_name}</Typography>
                </Grid>
                {features
                  ? features
                  : null}
              </Grid>
              <Grid container item xs={12} md={3} className={classes.scoreBlock}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {this.props.product.data.score_retailer
                          ? <TableRow>
                              <TableCell component="th" scope="row">
                                Avg. Retailer Rating
                              </TableCell>
                              <TableCell align="right">{Math.round(this.props.product.data.score_retailer/2.0*10)/10.0}/5</TableCell>
                            </TableRow>
                          : null}
                        {this.props.product.data.score_expert
                          ? <TableRow>
                              <TableCell component="th" scope="row">
                                Expert Rating
                              </TableCell>
                              <TableCell align="right">{Math.round(this.props.product.data.score_expert*10.0)/10.0}/10</TableCell>
                            </TableRow>
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container item xs={12} className={classes.retailerBlock}>
                  {retailers
                    ? retailers
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
    )

  }

}

export default withStyles(useStyles)(CategoryProduct)