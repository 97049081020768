import React from 'react';
import * as routes from '../constants/routes';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
}));

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://swervie.co/">
          Swervie, LLC
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

function StickyFooter() {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Typography variant="h6" align="center" gutterBottom>
                Swervie
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
              <Link variant="subtitle1" color="textSecondary" align="center" component="a" href={routes.ABOUT}>About Us</Link>
              <Typography variant="subtitle1" color="textSecondary" component="a"> | </Typography>
              <Link variant="subtitle1" color="textSecondary" align="center" component="a" href={routes.CONTACT}>Contact</Link>
              <Typography variant="subtitle1" color="textSecondary" component="a"> | </Typography>
              <Link variant="subtitle1" color="textSecondary" align="center" component="a" href={routes.PRIVACY}>Privacy Policy</Link>
              <Typography variant="subtitle1" color="textSecondary" component="a"> | </Typography>
              <Link variant="subtitle1" color="textSecondary" align="center" component="a" href={routes.TERMS}>Terms and Conditions</Link>
            </Typography>
            <Copyright />
        </footer>
    )
}

export default (StickyFooter);