import React from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';

import { nameToUrl } from '../utils/functions';
import * as routes from '../constants/routes';
import * as ga from '../utils/ga';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  categoryItemWrapper: {
    padding: theme.spacing(1),
  },
  categoryItem: {
    width: '100% !important',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

function SuperCategoryName(props) {

    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.categoryItemWrapper}>
            <h2>{props.name}</h2>
        </Grid>
    )

}

function CategoryItem(props) {

    const classes = useStyles();
    let history = useHistory();

    function handleCategoryClick() {
    
        var url = nameToUrl(props.category.data.name)
        
        var path = routes.HOME + 'search/' + url;

        ga.doGAEvent('super-category-list','category-click',url);

        history.push(path);
    
    }

    return (
        <Grid item xs={6} md={3} className={classes.categoryItemWrapper}>
            <ButtonBase
                component='a'
                className={classes.categoryItem}
                onClick={handleCategoryClick}
                href={routes.HOME + 'search/' + nameToUrl(props.category.data.name)}
                >
                <Paper className={classes.paper}>
                    {props.category.data.name}
                </Paper>
            </ButtonBase>
        </Grid>
    )

}

function SuperCategoryAddItem() {

    const classes = useStyles();

    return (
        <Grid item xs={6} md={3} className={classes.categoryItemWrapper}>
            <ButtonBase
                component='a'
                className={classes.categoryItem}
                onClick={event => {ga.doGAEvent('super-category-list','category-add-click');}}
                >
                <Paper className={classes.paper}>
                    {"Add a category"}
                </Paper>
            </ButtonBase>
        </Grid>
    )

}

export default function SuperCategory(props) {

    if (props.supercategory.categories) {

        var categories = props.supercategory.categories.map((category, index) => {
  
            return (
                <CategoryItem key={index} category={category}/>
            )
        });
  
    }

    return (
        <Grid container item>
            {props.supercategory.data
                ? <SuperCategoryName name={props.supercategory.data.name} />
                : null}
            {categories
                ? categories
                : null}
            <SuperCategoryAddItem />
        </Grid>
    );
}