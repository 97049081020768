import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore
import 'firebase/auth';   // for firebase auth

// Currently using dev env
const prodConfig = {
    apiKey: process.env.REACT_APP_API_KEY_DEV,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_DB_URL_DEV,
    projectId: process.env.REACT_APP_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV
};

const devConfig = {
    apiKey: process.env.REACT_APP_API_KEY_DEV,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_DB_URL_DEV,
    projectId: process.env.REACT_APP_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV
};

console.log(process.env.NODE_ENV);

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth }