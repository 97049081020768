import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import SignUp from './SignUp';
import * as ga from '../utils/ga';

class SignInPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }

    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);

  }

  componentDidMount() {
    ga.doGAPageView('/sign-in');
  }

  handleSignIn() {
    if (this.props.history.action === "POP") {
      this.props.route_inputs.history.go(-2);
    } else {
      this.props.route_inputs.history.goBack();
    }
  }

  handleSignUp() {
    if (this.props.history.action === "POP") {
      this.props.history.go(-2);
    } else {
      this.props.history.goBack();
    }
  }

  render() {

    return(
      <Grid container justify="center">
        <Grid item xs={12}>
          <SignUp onSignUp={this.handleSignUp} onSignIn={this.handleSignIn} signUp={false}/>
        </Grid>
      </Grid>
    )
  }

}

export default withRouter(SignInPage);