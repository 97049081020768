import React, { Component } from 'react';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';

import { db } from '../firebase/firebase-config';
import * as ga from '../utils/ga';

import { withStyles } from '@material-ui/core/styles';
import SuperCategory from './SuperCategory';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  containerSpacer: {
    padding: theme.spacing(1),
  },
  categoryItem: {
    width: '100% !important',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
});

class SuperCategoryList extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }

  }

  componentDidMount() {

    ga.doGAPageView('/super-category-list');

    var category_results = []
    var sub_category_results = []
    db.collection("categories")
      .where("is_super_category", "==", true)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

            db.collection("categories")
              .where("parent_category", "==", doc.ref)
              .where("is_super_category", "==", false)
              .limit(12)
              .get()
              .then((sub_querySnapshot) => {
                sub_querySnapshot.forEach((sub_doc) => {
                    
                    sub_category_results.push({
                      ref: sub_doc.ref,
                      id: sub_doc.id,
                      data: sub_doc.data()
                    });
                    
                  })
                  
                  category_results.push({
                    ref: doc.ref,
                    id: doc.id,
                    data: doc.data(),
                    categories: sub_category_results
                  });

                  this.setState({
                    super_categories: category_results
                  });

              });

          })

      });

  }

  render() {

    if (this.state.super_categories) {

      var supercategories = this.state.super_categories.map((supercategory, index) => {

        return (
          <SuperCategoryItem key={index} supercategory={supercategory}/>
        )
      });

    }

    const { classes } = this.props

    return (
      <Grid container className={classes.containerSpacer}>
        {supercategories
          ? supercategories
          : null}
      </Grid>
    );

  }

}

function SuperCategoryItem(props) {
    
  return (
    <SuperCategory supercategory={props.supercategory}/>
  );

}

export default withRouter(withStyles(useStyles)(SuperCategoryList))