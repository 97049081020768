import ReactGA from 'react-ga';

const prodConfig = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_PROD
const devConfig = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_DEV

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

ReactGA.initialize(config);

// Page View
export const doGAPageView = (path) =>
    ReactGA.pageview(path);

// Event
export const doGAEvent = (categoryName, actionName) =>
    ReactGA.event({
        category: categoryName,
        action: actionName
    });

// Set User
export const doGASetUser = (uid) =>
    ReactGA.set({ userId: uid });