import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { auth } from '../firebase/firebase-config';
import * as ga from '../utils/ga';

import { UserContext } from '../hooks/UserProvider';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  containerSpacer: {
    padding: theme.spacing(1),
  },
  categoryItem: {
    width: '100% !important',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
});

class SignUp extends Component {
  constructor(props) {
    super(props)

    var signIn = true
    if (props.signUp) {
      signIn = false
    }

    this.state = {
      user: null,
      email: '',
      password: '',
      passwordOne: '',
      passwordTwo: '',
      emailValid: false,
      passwordValid: false,
      boxChecked: false,
      error: false,
      errorObj: null,
      feedback: null,
      signIn: signIn
    }

    this.onSignInSubmit = this.onSignInSubmit.bind(this);
    this.onPasswordResetClick = this.onPasswordResetClick.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSignInPasswordChange = this.handleSignInPasswordChange.bind(this);
    this.handleSignInClick = this.handleSignInClick.bind(this);
    this.handleSignUpClick = this.handleSignUpClick.bind(this);

  }

  componentDidMount() {
    // ga.doGAPageView(routes.SIGN_UP);
  }

  handleEmailChange(event) {
    if (event) {
      var isValid = false
      if (!event.target.checkValidity() || event.target.value.indexOf("@") < 1 || event.target.value.indexOf(".") < 0) {
        // form is invalid set to invalid state
        isValid = false;
      } else {
        isValid = true;
      }
      this.setState({
        email: event.target.value,
        emailValid: isValid,
        error: false,
        errorObj: null,
        feedback: null
      });
    }
  }

  isSignUpInvalid() {
    if (this.state.emailValid && this.state.passwordValid && this.state.passwordOne === this.state.passwordTwo && this.state.boxChecked) {
      return false
    } else {
      return true
    }
  }

  onSignInSubmit = (event) => {

    const {
      email,
      password,
    } = this.state;

    if (this.state.signIn) {

      auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          ga.doGAEvent('auth', 'sign_in');
          console.log('signed in');
          this.props.history.goBack();
        })
        .catch(error => {
          this.setState({error: true, errorObj: error});
        });

    } else {

      // merge anonymous data with new account

      auth.createUserWithEmailAndPassword(email, password)
        .then(() => {
          ga.doGAEvent('auth', 'sign_up');
          console.log('signed up');
        })
        .catch(error => {
          // if error because user already exists, sign user in
          if (error.code === 'auth/email-already-in-use') {
            auth.signInWithEmailAndPassword(email, password)
              .then(() => {
                ga.doGAEvent('auth', 'sign_in');
                console.log('signed in');
                this.props.history.goBack();
              })
              .catch((error2) => {
                this.setState({error: true, errorObj: error});
              });
          } else {
            this.setState({error: true, errorObj: error});
          }
        });

    }

    event.preventDefault();

  }

  onPasswordResetClick() {
    auth.sendPasswordResetEmail(this.state.email).then(() => {
      // Email sent.
      this.setState({feedback: 'We have sent a password reset email to the address above'});
    }).catch((error) => {
      // An error happened.
      if (error.code === 'auth/user-not-found') {
        this.setState({error: true, errorObj: error, feedback: null});
      } else {
        this.setState({error: true, errorObj: error, feedback: null});
      }
    });
  }

  getSignInValidationState() {
    if (this.state.error) {
      return true
    } else {
      return false
    }
  }

  parseSignInError(errorObj) {
    if (errorObj) {
      if (errorObj.code === "auth/wrong-password") {
        return "The password you entered did not match our records. Please double-check and try again."
      } else if (errorObj.code === "auth/user-not-found") {
        return "The email you entered did not match our records. Please double-check and try again."
      } else {
        return errorObj.message
      }
    } else {
      return null
    }
  }

  handleSignInPasswordChange(event) {
    if (event) {
      var isValid = false
      if (!event.target.checkValidity() || event.target.value.length < 8) {
        // form is invalid set to invalid state
        isValid = false;
      } else {
        isValid = true;
      }
      this.setState({
          password: event.target.value,
          passwordValid: isValid,
          error: null,
          feedback: null
        });
    }
  }

  isSignInInvalid() {
    if (this.state.emailValid && this.state.passwordValid) {
      return false
    } else {
      return true
    }
  }

  handleSignUpClick(e) {
    e.preventDefault();
    this.setState({
      signIn: false,
      error: false,
      errorObj: null,
      feedback: null,
    });
  }

  handleSignInClick(e) {
    e.preventDefault();
    this.setState({
      signIn: true,
      error: false,
      errorObj: null,
      feedback: null,
    });
  }

  render() {

    const {
      email,
      password,
      signIn,
      feedback,
      emailValid,
    } = this.state;

    const { classes } = this.props

    return (
      <div>
        <Container maxWidth="xs">
          <Grid item>
            <UserContext.Consumer>
              {({user,logout}) => 
                <div>
                  {user
                    ? <div>
                        {user.email
                          ? <div>
                              <p>logged in</p>
                            </div>
                          : <div className={classes.paper}>
                              <form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {signIn
                                      ? <Typography component="h1" variant="h5">
                                          Sign In
                                        </Typography>
                                      : <Typography component="h1" variant="h5">
                                          Sign Up
                                        </Typography>
                                    }
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={event => this.handleEmailChange(event)}
                                        error={this.state.error}
                                      />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      variant="outlined"
                                      required
                                      fullWidth
                                      name="password"
                                      label="Password"
                                      type="password"
                                      id="password"
                                      autoComplete="current-password"
                                      value={password}
                                      onChange={event => this.handleSignInPasswordChange(event)}
                                      error={this.state.error}
                                      helperText={this.parseSignInError(this.state.errorObj)}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    {signIn
                                      ? <div>
                                          <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={this.isSignInInvalid()}
                                            onClick={this.onSignInSubmit}
                                          >
                                            Sign In
                                          </Button>
                                          <Grid container justify="flex-end">
                                            <Grid item>
                                              <Link
                                                href="#"
                                                variant="body2"
                                                onClick={this.handleSignUpClick}>
                                                New to Swervie? Sign up
                                              </Link>
                                            </Grid>
                                          </Grid>
                                          {emailValid
                                            ? <Grid container justify="flex-end">
                                                <Grid item>
                                                  <Link
                                                    href="#"
                                                    variant="body2"
                                                    onClick={this.onPasswordResetClick}>
                                                    Reset password
                                                  </Link>
                                                </Grid>
                                              </Grid>
                                            : null
                                            }
                                        </div>
                                      : <div>
                                          <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={this.isSignInInvalid()}
                                            onClick={this.onSignInSubmit}
                                          >
                                            Sign Up
                                          </Button>
                                          <Grid container justify="flex-end">
                                            <Grid item>
                                              <Link
                                                href="#"
                                                variant="body2"
                                                onClick={this.handleSignInClick}>
                                                Already have an account? Sign in
                                              </Link>
                                            </Grid>
                                          </Grid>
                                        </div>
                                    }
                                  </Grid>
                                  {feedback
                                    ? <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="center">{feedback}</Typography>
                                      </Grid>
                                    : null
                                  }
                                </Grid>
                              </form>
                            </div>
                        }
                      </div>
                      : null
                    }
                </div>
              }
            </UserContext.Consumer>
          </Grid>
        </Container>
      </div>
    )

  }

}

export default withRouter(withStyles(useStyles)(SignUp));
