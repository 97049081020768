import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { db } from '../firebase/firebase-config';
import * as ga from '../utils/ga';
import CategoryProduct from './CategoryProduct';
import CategoryProductAdd from './CategoryProductAdd';
import BuyingGuide from './BuyingGuide';
import Filters from './Filters';

import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  containerSpacer: {
    padding: theme.spacing(1),
  },
  categoryItem: {
    width: '100% !important',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
});

class CategoryList extends Component {
  constructor(props) {
    super(props)

    let { category_name } = this.props.match.params;

    this.state = {
      category_name: category_name,
      category_title: category_name,
      category_description: null,
      category_id: null,
      addProductValue: null,
    };

    this.handleAddProductClick = this.handleAddProductClick.bind(this);
    this.handleAddProductChange = this.handleAddProductChange.bind(this);

  }

  componentDidMount() {

    ga.doGAPageView(this.props.match.path);

    var product_results = []
    db.collection("categories")
      .where("name_lower", "==", this.props.match.params.category_name.toLowerCase())
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            var category_results = {
              ref: doc.ref,
              id: doc.id,
              data: doc.data()
            };

            var category_filters = category_results.data.features.filter((feature) => (feature.filter))

            this.setState({
              category: category_results,
              category_name: category_results.data.name,
              category_description: category_results.data.description,
              category_title: category_results.data.title,
              filters: category_filters
            });

            db.collection("products")
              .where("categories", "array-contains", doc.id)
              .where("approved", "==", true)
              .limit(50)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((product_doc) => {
                    product_results.push({
                      ref: product_doc.ref,
                      id: product_doc.id,
                      data: product_doc.data()
                    });
                })
                this.setState({
                  products: product_results
                });
        
              });

        })

      });
    

  }

  handleAddProductChange(event) {
    ga.doGAEvent('category-list','product-add-change');
    this.setState({addProductValue: event.target.value});
  }

  handleAddProductClick = (event) => {
    ga.doGAEvent('category-list','product-add-click');
    var newproduct = {
      created_url: this.state.addProductValue,
      categories: [this.state.category_id],
      urls:[this.state.addProductValue],
      approved: false,
    }
    db.collection('products').add(newproduct)
      .then((product_result) => {
        this.setState({product: product_result});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleFilterChange = (event) => {
    if (this.state.filters[event.target.name].type === 'boolean') {
      var new_filters = this.state.filters
      new_filters[event.target.name].value = event.target.checked
      this.setState({filters: new_filters});
    }
  };

  render() {  
    
    const { classes } = this.props

    if (this.state.products) {

      if (this.state.filters) {
        
        var products_filtered = this.state.products.filter((product) => {

          var filtered = true
          
          this.state.filters.map((filter) => {

            if (filter.type = 'boolean') {

              if (filter.value === true) {

                if (product.data.features[filter.name] !== filter.value) {
                  filtered = false
                } else {
                  // DO NOTHING
                }

              }

            } else if (filter.type = 'range') {

              // TODO: PRICE FILTER

            }

          })

          return filtered

        })

      } else {

        var products_filtered = this.state.products

      }

      var products_array = products_filtered.sort((a, b) => (a.data.score_overall < b.data.score_overall) ? 1 : -1)

      var products = products_array.map((product, index) => {

        return (
          <CategoryProduct product={product} category={this.state.category}/>
        )
      });

    }

    var buying_guide = null

    if (this.state.category) {
      if (this.state.category.data.buying_guide) {
        buying_guide = this.state.category.data.buying_guide
      }
    }

    var d = new Date();
    var current_year = d.getFullYear();

    var description = "The best place to start shopping for " + this.state.category_name + ", with product ratings, buying guides, and links to the best retailers"

    return (
      <div>
        <Helmet>
          <title>Top {this.state.category_name} of {current_year} - Swervie</title>
          <meta name="description" content={description} />
        </Helmet>
        <Grid container className={classes.containerSpacer}>
          <Grid item xs={12}>
            <Typography variant={'h5'}>{this.state.category_name}</Typography>
          </Grid>
          <Grid container xs={12} md={2} direction="row" justify="center">
          </Grid>
          <Grid container xs={12} md={10} direction="row" justify="flex-end">
            <CategoryProductAdd value={this.state.addProductValue} handleAddProductClick={this.handleAddProductClick} handleAddProductChange={this.handleAddProductChange}/>
          </Grid>
          <Grid container xs={12} md={2} direction="column" justify="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant={'h6'}>Filters</Typography>
            </Grid>
            <Grid item>
              <Filters filters={this.state.filters} onFilterChange={this.handleFilterChange}/>
            </Grid>
          </Grid>
          <Grid container xs={12} md={10} direction="row" justify="center">
            {products
              ? products
              : null}
            {buying_guide
              ? <BuyingGuide buyingGuide={buying_guide}/>
              : null}
          </Grid>
        </Grid>
      </div>
    );

  }

}

export default withRouter(withStyles(useStyles)(CategoryList))