import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function CategoryList(props) {

    if (props.filters) {

        var filters = props.filters.map((filter, index) => {

        if (filter.type === "boolean") {
            
            return (
            <FormControlLabel
                control={
                <Checkbox
                    checked={filter.value}
                    onChange={props.onFilterChange}
                    name={index}
                    color="primary"
                />
                }
                label={filter.name}
            />
            )

        } else if (filter.type === "scale") {
            
            // TODO: PRICE FILTER

        }

        })

    }

    return (
        <div>
            {filters
                ? filters
                : null}
        </div>
    );

}

export default (CategoryList)