import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    gridSpacer: {
        padding: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      width: '100%',
    },
  }));

export default function BuyingGuide(props) {

    const classes = useStyles();

    if (props.buyingGuide) {

        var sections = props.buyingGuide.map((section, index) => {
  
            return (
                <Typography variant={section.variant} gutterBottom>{section.content}</Typography>
            )
        });
  
      }
    
    return (
        <Grid item xs={12} className={classes.gridSpacer}>
            <Paper className={classes.paper}>
                {sections
                    ? sections
                    : null}
            </Paper>
        </Grid>
    )
  
  }