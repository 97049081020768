import React, { Component } from 'react';
import * as routes from '../constants/routes';
import * as ga from '../utils/ga';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  containerSpacer: {
    padding: theme.spacing(1),
  },
  categoryItem: {
    width: '100% !important',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
});

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        ga.doGAPageView(routes.CONTACT);
    }

    render() {

        const { classes } = this.props

        return (
            <Grid container xs={12} justify={'center'} className={classes.containerSpacer}>
              <Paper className={classes.paper}>
                <Typography variant={'h5'}>Contact</Typography>
                <Typography variant={'subtitle2'}>We appreciate all feedback and questions. Email us at contact@swervie.co</Typography>
              </Paper>
            </Grid>
        )
    }

}

export default withStyles(useStyles)(Contact);