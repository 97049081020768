import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Container } from '@material-ui/core';

import Header from './components/Header';
import SuperCategoryList from './components/SuperCategoryList'
import CategoryList from './components/CategoryList'
import SignInPage from './components/SignInPage';
import About from './components/About';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import StickyFooter from './components/StickyFooter';
import TermsAndConditions from './components/TermsAndConditions';

import UserProvider from './hooks/UserProvider';

import { makeStyles } from '@material-ui/core/styles';

import * as routes from './constants/routes';

export default function App() {

  const useStyles = makeStyles(theme => ({
    bodyContainer: {
      minHeight: "600px",
      padding: theme.spacing(0),
    }
  }));

  const classes = useStyles();

  return (

    <UserProvider>

      <Header/>

        <Container className={classes.bodyContainer}>

          <Switch>
            <Route exact path={routes.CATEGORY} component={() => <CategoryList />} />
            <Route exact path={routes.SIGNIN} component={() => <SignInPage />} />
            <Route exact path={routes.ABOUT} component={() => <About />} />
            <Route exact path={routes.CONTACT} component={() => <Contact />} />
            <Route exact path={routes.PRIVACY} component={() => <Privacy />} />
            <Route exact path={routes.TERMS} component={() => <TermsAndConditions />} />
            <Route path={'/'} component={() => <SuperCategoryList />} />
          </Switch>

        </Container>

      <StickyFooter />

    </UserProvider>

  )

}
