import React, { Component, createContext } from "react";
import { auth } from "../firebase/firebase-config";

export const UserContext = createContext({ user: null, logout: null });

class UserProvider extends Component {
    
    logout = () => {
        auth.signOut().then(function() {
            // Sign-out successful.
            // console.log('sign-out successful');
        }).catch(function(error) {
            // An error happened.
            // console.log(error);
        });
    }
    
    state = {
        user: null
    };

    componentDidMount = () => {
        // console.log('auth mounted');
        auth.onAuthStateChanged(userAuth => {
            // console.log(userAuth);
            if (userAuth === null) {
                console.log('anonymous sign in');
                // anonymous sign in
                auth.signInAnonymously().catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
            } else {
                // set user
                // console.log('set user');
                this.setState({user: userAuth});
            }
        });
    };

    render() {
        return (
            <UserContext.Provider value={{user: this.state.user, logout: this.logout}}>
                {this.props.children}
            </UserContext.Provider>
        );
    }

}

export default UserProvider;