import React, { Component } from 'react';
import * as routes from '../constants/routes';
import * as ga from '../utils/ga';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        ga.doGAPageView(routes.ABOUT);
    }

    render() {
        return (
            <div>
                <h3>Welcome to Swervie</h3>
                <p>We, as a community, are building the best place to start online shopping. Complete with user generated ratings, reviews, and buying guides, Swervie helps you find the right product and order it from the best retailer.</p>
            </div>
        )
    }

}

export default (About);