import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    containerSpacer: {
        padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
      width: '100%',
      display: 'inline-flex',
    },
    addProduct: {
        // padding: theme.spacing(1)
    },
    button: {
        display: 'inline-block',
    }
  }));

export default function CategoryProductAdd(props) {

    const classes = useStyles();
    
    return (
        // <div className={classes.root}>
            <Grid container item direction="row" justify="center" className={classes.containerSpacer} xs={12} md={9} >
                {/* <Paper className={classes.paper}> */}
                {/* <form className={classes.root} noValidate autoComplete="off"> */}
                {/* <form noValidate autoComplete="off" className={classes.addProduct}> */}
                    {/* <Grid container sm={12} md={9}> */}
                        <Grid item xs={8}>
                            <form className={classes.addProduct}>
                            {/* <TextField id="standard-basic" label="Standard" /> */}
                            {/* <TextField id="filled-basic" label="Filled" variant="filled" /> */}
                                <TextField
                                    id="outlined-basic"
                                    margin="dense"
                                    size="small"
                                    // label="Add Product"
                                    placeholder="https://www.swervie.co/"
                                    fullWidth
                                    value={props.value}
                                    onChange={props.handleAddProductChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LinkIcon />
                                            </InputAdornment>
                                        ),
                                        }}
                                    />
                            </form>
                        </Grid>
                        <Grid item xs={4}>
                            <Button className={classes.button} variant="contained" color="primary" size="small" onClick={props.handleAddProductClick}>
                                Add Product
                            </Button>
                        </Grid>
                    {/* </Grid> */}
                {/* </Paper> */}
            </Grid>
        // </div>
    )
  
  }